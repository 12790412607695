import { __decorate } from "tslib";
import { Component, Vue, Watch } from 'vue-property-decorator';
import { Alert, AlertTheme } from '@component/Alert';
import { Inject } from '@plugin/inversify';
import { Screen } from '@component/Screen';
import { ContentRepositoryType } from '@content/contracts';
import { NoArticles } from '@profile/components/ArticleDetails/NoArticles.vue';
import { ArticlesList } from '@profile/components/ArticleDetails/ArticlesList.vue';
import { RelatedServiceType } from '@service/related';
let ArticlesView = class ArticlesView extends Vue {
    constructor() {
        super(...arguments);
        this.alert = null;
        this.articles = null;
        this.isLoading = false;
        this.relatedService = null;
    }
    get isWaitingForAuth() {
        return this.$store.getters['profile/isWaitingForAuth'];
    }
    get me() {
        return this.$store.getters['profile/me'];
    }
    get subscription() {
        return this.$store.getters['profile/subscription'];
    }
    async loadArticles() {
        this.isLoading = true;
        try {
            this.articles = await this.contentRepository.loadUserArticles();
            // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
            // @ts-ignore
            this.composeRelated(this.articles._meta);
        }
        catch (e) {
            console.error(e);
            this.alert = {
                theme: AlertTheme.Warning,
                message: e.message,
                useDefaultIcon: false,
                dismissible: true,
                autoTimeout: 5000
            };
        }
        finally {
            this.isLoading = false;
        }
    }
    composeRelated(relatedData) {
        if (typeof this.$container === 'undefined') {
            throw new Error('WithLayout.composeContent(): [this.$container] is undefined!');
        }
        this.relatedService = this.$container.get(RelatedServiceType);
        this.relatedService.storeRelated({ ...relatedData });
    }
    onAuthChange() {
        if (!this.isWaitingForAuth) {
            this.loadArticles();
        }
    }
};
__decorate([
    Inject(ContentRepositoryType)
], ArticlesView.prototype, "contentRepository", void 0);
__decorate([
    Watch('isWaitingForAuth')
], ArticlesView.prototype, "onAuthChange", null);
ArticlesView = __decorate([
    Component({
        name: 'ArticlesView',
        components: { Alert, ArticlesList, NoArticles, Screen },
        mounted() {
            this.onAuthChange();
        }
    })
], ArticlesView);
export { ArticlesView };
export default ArticlesView;
