import { __decorate } from "tslib";
import { Component, Prop, Vue } from 'vue-property-decorator';
import { WindowType } from '@movecloser/front-core';
import { ThumbnailsList } from '@d24/modules';
import { Addon } from '@d24/modules/front';
import { Inject } from '@plugin/inversify';
let ArticlesList = class ArticlesList extends Vue {
    constructor() {
        super(...arguments);
        this.articlesWithThumbnail = null;
    }
    async resolveArticles() {
        this.articlesWithThumbnail = await Promise.all([...this.articles].map(article => this.resolveArticle(article)));
    }
    async resolveArticle(article) {
        var _a;
        const cover = await this.resolveCover(article.getProperty(Addon.Cover));
        const author = await this.relatedService.resolve(article.author);
        return {
            paywall: false,
            sponsored: false,
            thumbnail: undefined,
            ...article,
            properties: {
                ...article.properties,
                cover
            },
            author,
            urlPath: (_a = article.fullUrl) !== null && _a !== void 0 ? _a : article.urlPath
        };
    }
    async resolveCover(cover) {
        if (typeof cover === 'object' && cover.image) {
            try {
                const image = await this.relatedService.resolve(cover.image);
                return {
                    ...cover,
                    image
                };
            }
            catch (e) {
                console.warn(e);
            }
        }
        return cover;
    }
};
__decorate([
    Prop({ type: Array, required: true })
], ArticlesList.prototype, "articles", void 0);
__decorate([
    Prop({ type: Object, required: true })
], ArticlesList.prototype, "relatedService", void 0);
__decorate([
    Inject(WindowType)
], ArticlesList.prototype, "windowService", void 0);
ArticlesList = __decorate([
    Component({
        name: 'ArticlesList',
        components: { ThumbnailsList },
        created() {
            this.resolveArticles();
        }
    })
], ArticlesList);
export { ArticlesList };
export default ArticlesList;
