import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
let NoArticles = class NoArticles extends Vue {
};
NoArticles = __decorate([
    Component({
        name: 'NoArticles'
    })
], NoArticles);
export { NoArticles };
export default NoArticles;
